import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useUserStore } from "components/stores/UserStore";

import { useCookieConsentStore } from "./CookieConsentStore";
import { useCookieConsentContext } from "./CookiesConsentContext";

const CookiesConsentModal = () => {
  const user = useUserStore((s) => s.user);
  const consent = useCookieConsentStore((s) => s.consent);
  const setConsent = useCookieConsentStore((s) => s.setConsent);

  const { isCookieConsentModalOpen, setIsCookieConsentModal } =
    useCookieConsentContext();

  useEffect(() => {
    if (user) {
      if (consent.get(user.UserV2?.user?.id ?? "") === undefined) {
        setIsCookieConsentModal(true);
      }
    }
  }, [user, consent, setIsCookieConsentModal]);

  if (!user) return null;

  const handleAcceptAll = () => {
    if (new Map(consent).get(user.UserV2?.user?.id ?? "") === undefined) {
      setConsent(user.UserV2?.user?.id ?? "", { necessary: true });
      setIsCookieConsentModal(false);
    } else {
      setConsent(user.UserV2?.user?.id ?? "", { necessary: true });
      window.location.reload();
    }
  };

  return (
    <>
      <Dialog
        open={isCookieConsentModalOpen}
        onClose={() => {
          if (consent !== null) {
            setIsCookieConsentModal(false);
          }
        }}
      >
        <DialogTitle>
          <FormattedMessage id="COOKIE_CONSENT.TITLE" />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography my={1} variant="body1">
            <FormattedMessage id="COOKIE_CONSENT.TEXT" />
          </Typography>

          <Typography my={1} variant="body1">
            <FormattedMessage id="COOKIE_CONSENT.TEXT2" />
          </Typography>
          <Stack justifyContent="center" sx={{ mt: 4 }} alignItems="center">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled
                    defaultChecked
                    size="medium"
                  />
                }
                label="Strictly necessary"
                labelPlacement="top"
              />
            </FormGroup>
          </Stack>
          <DialogActions>
            <Button variant="contained" onClick={handleAcceptAll}>
              <FormattedMessage id="GLOBAL.ACCEPT" />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CookiesConsentModal;
