import {
  BugReportOutlined,
  HelpOutline,
  LightbulbOutlined,
  LiveHelpOutlined,
  TextsmsOutlined,
} from "@mui/icons-material";
import { Link, SpeedDial, SpeedDialAction } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { Link as RouterLink, useLocation } from "react-router-dom";

import BugReportDialog from "./BugReportDialog";
import HelpDialog from "./HelpDialog";
import SuggestionsDialog from "./SuggestionsDialog";

function Feedback() {
  const helpPopupState = usePopupState({ variant: "dialog", popupId: "help" });
  const bugReportPopupState = usePopupState({
    variant: "dialog",
    popupId: "bugReport",
  });
  const suggestionsPopupState = usePopupState({
    variant: "dialog",
    popupId: "suggestions",
  });

  const location = useLocation();

  const showFaq = useFlag("faq");

  const actions = [
    {
      show: true,
      icon: <TextsmsOutlined />,
      name: "Help!",
      ...bindTrigger(helpPopupState),
    },
    {
      show: location.pathname.startsWith("/login") ? false : true,
      icon: <BugReportOutlined />,
      name: "Report a bug issue",
      ...bindTrigger(bugReportPopupState),
    },
    {
      show: location.pathname.startsWith("/login") ? false : true,
      icon: <LightbulbOutlined />,
      name: "Feature requests",
      ...bindTrigger(suggestionsPopupState),
    },
    {
      show: location.pathname.startsWith("/login") || !showFaq ? false : true,
      icon: (
        <Link
          component={RouterLink}
          to="/faq"
          sx={{
            color: "gray",
            position: "relative",
            top: 4,
            "&:hover": {
              color: "gray",
            },
          }}
        >
          <LiveHelpOutlined />
        </Link>
      ),
      name: "FAQ",
    },
  ];

  return (
    <>
      {!location.pathname.startsWith("/map") && (
        <SpeedDial
          ariaLabel="Feedback forms"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<HelpOutline />}
        >
          {actions.map(
            (action) =>
              action.show && (
                <SpeedDialAction
                  {...action}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                />
              ),
          )}
        </SpeedDial>
      )}
      <HelpDialog {...helpPopupState} />
      <SuggestionsDialog {...suggestionsPopupState} />
      <BugReportDialog {...bugReportPopupState} />
    </>
  );
}

export default Feedback;
