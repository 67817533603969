import * as Sentry from "@sentry/react";
import { Route } from "react-router-dom";

import { history } from "utils/history";

export const SentryRoute = Sentry.withSentryRouting(Route);

export async function initializeSentry() {
  if (import.meta.env.VITE_SENTRY_ALLOW_ON_LOCAL || import.meta.env.PROD) {
    const environment = getEnvironment();

    Sentry.init({
      dsn: "https://2576653f1a1e72a1d5bbda153a2a278d@o4507413174288384.ingest.de.sentry.io/4507430174064720",
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserTracingIntegration(),
      ],
      environment,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
      // Capture Replay for 0% of all sessions, but 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      debug: true,
      ignoreErrors: ["_getTokenUsingRefreshToken"],
    });

    if (environment === "production" || environment === "development") {
      const { replayIntegration } = await import("@sentry/browser");
      Sentry.addIntegration(replayIntegration());
    }
  }
}

function getEnvironment() {
  if (import.meta.env.VITE_VERCEL_ENV === "production") {
    return "production";
  }
  if (import.meta.env.REACT_APP_ENVIRONMENT === "production") {
    return "production";
  }

  if (import.meta.env.VITE_VERCEL_ENV === "preview") {
    if (import.meta.env.VITE_VERCEL_GIT_COMMIT_REF === "main") {
      return "development";
    }
    return "preview";
  }

  if (import.meta.env.VITE_VERCEL_ENV === "development") {
    return "local";
  }
  if (import.meta.env.REACT_APP_ENVIRONMENT === "local") {
    return "local";
  }

  return "unknown";
}
