import Plausible from "plausible-tracker";

import { useUserStore } from "components/stores/UserStore";

const {
  enableAutoOutboundTracking,
  enableAutoPageviews,
  trackEvent: trackEventPlausible,
  trackPageview,
} = Plausible({
  // leverage nginx proxy on production to avoid blocking requests by adblockers
  // apiHost:
  //   window.location.origin === "https://insight.plugport.no"
  //     ? "https://insight.plugport.no"
  //     : "https://plausible.io",
  trackLocalhost: true,
});

export const defaultPlausibleProps = {
  orgType: useUserStore.getState().user?.UserV2?.organization?.Type ?? "",
  role: useUserStore.getState().user?.UserV2?.user?.role ?? "",
};

const trackEvent: typeof trackEventPlausible = (
  eventName,
  options,
  eventData,
) => {
  trackEventPlausible(
    eventName,
    {
      ...options,
      props: {
        ...defaultPlausibleProps,
        ...options?.props,
      },
    },
    eventData,
  );
};

export {
  enableAutoOutboundTracking,
  enableAutoPageviews,
  trackEvent,
  trackPageview,
};
